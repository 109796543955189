import { Box, Typography } from "@mui/material";
import { useImmer } from "use-immer";
import { type RuleGroupType } from "react-querybuilder";
import { BoxHeader } from "@components/generic/generic.component";
import ReactQueryBuilder from "@components/query-builder/query-builder.component";
import { type QbConfig } from "@models/query-builder-config.model";
import handleValueForBetweenOperator from "@utils/handle-between-operator.util";

interface ViewContentFilterProps {
  onChange: (filter: RuleGroupType) => void;
  filter: RuleGroupType;
  showErrors: boolean;
  disabled?: boolean;
}

const ViewContentFilter = ({ onChange, filter, showErrors, disabled }: ViewContentFilterProps): JSX.Element => {
  const [qbConfig] = useImmer<QbConfig>({ isComplexView: true });

  const handleQueryChange = (newFilter: RuleGroupType): void => {
    const updatedFilter = handleValueForBetweenOperator(filter, newFilter);
    onChange(updatedFilter);
  };

  return (
    <>
      <BoxHeader alignItems="center" display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex">
          <Typography variant="h5" color={theme => theme.palette.grey[800]}>
            Query
          </Typography>
        </Box>
      </BoxHeader>
      <Box>
        <ReactQueryBuilder
          query={filter}
          disabled={disabled}
          onQueryChange={handleQueryChange}
          config={qbConfig}
          showErrors={showErrors}
        />
      </Box>
    </>
  );
};

ViewContentFilter.defaultProps = {
  disabled: false
};

export default ViewContentFilter;
