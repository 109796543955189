import { MaterialValueEditor } from "@react-querybuilder/material";
import dayjs from "dayjs";
import { type ValueEditorProps, type ValidationResult } from "react-querybuilder";
import MuiDatePicker from "@components/date-picker/date-picker.component";
import PaginatedMultiSelect from "./paginated-multiselect/paginated-multiselect.component";
import NestedSelect from "./nested-select/nested-select.component";

const composeErrorMessage = (validation: boolean | ValidationResult | undefined, label: string): JSX.Element => {
  const { valid, reasons } = validation as { valid: boolean, reasons: string[] };

  if (!valid) {
    if (reasons) {
      return <div style={{ color: "red" }}>{reasons.join(", ")}</div>;
    }

    return <div style={{ color: "red" }}>{label} field is invalid</div>;
  }

  return <div />;
};

const CustomValueEditor = ({ fieldData, handleOnChange, ...props }: ValueEditorProps): JSX.Element => {
  const { validation, operator } = props;

  switch (fieldData.datatype) {
    case "paginatedSelect":
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PaginatedMultiSelect
            menuPlacement="auto"
            disabled={false}
            className=""
            closeMenuOnSelect={false}
            isClearable
            notPaginated={!!fieldData?.notPaginated}
            name={fieldData.name}
            url={`ViewFilters/${fieldData.name}`}
            placeholder={`Select ${fieldData.label}...`}
            onChange={handleOnChange}
            value={props?.value}
            {...props}
          />
          {validation != null && composeErrorMessage(validation, fieldData.label)}
        </div>
      );
    case "nestedSelect":
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <NestedSelect
            url={`ViewFilters/${fieldData.name}`}
            onChange={handleOnChange}
            value={props?.value}
          />
          {validation != null && composeErrorMessage(validation, fieldData.label)}
        </div>
      );

    case "date":

      if (operator !== "futureRange" && operator !== "pastRange") {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MuiDatePicker
              label=""
              variant="standard"
              onChange={handleOnChange}
              value={props?.value ? dayjs(props?.value) : null}
              date={props?.value}
              format="MM/DD/YYYY"
            />
            {validation != null && composeErrorMessage(validation, fieldData.label)}
          </div>
        );
      }

      props.inputType = "number";
      props.value = +props.value > 1000 ? "" : props.value;
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <MaterialValueEditor
            {...props}
            fieldData={fieldData}
            handleOnChange={handleOnChange}
          />
          {validation != null && composeErrorMessage(validation, fieldData.label)}
        </div>
      );
    default:
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <MaterialValueEditor
            {...props}
            fieldData={fieldData}
            handleOnChange={handleOnChange}
          />
          {validation != null && composeErrorMessage(validation, fieldData.label)}
        </div>
      );
  }
};

export default CustomValueEditor;
