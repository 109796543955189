import { type RuleGroupType, type RuleType } from "react-querybuilder";

const handleNewRule = (rule: RuleType): RuleType => {
  if (rule.operator === "between") {
    const value = String(rule.value);
    return { ...rule, value: `${value},${value}` };
  }

  if (rule.operator === "beginsWith") {
    return { ...rule, value: String(rule.value) };
  }

  return rule;
};

const handleChangeFromBetween = (rule: RuleType, oldRule: RuleType): RuleType => {
  const oldValue = String(oldRule.value);
  return { ...rule, value: oldValue.split(",")[0] };
};

const handleChangeToBetween = (rule: RuleType): RuleType => {
  const value = String(rule.value);
  return { ...rule, value: `${value},${value}` };
};

const handleValueForBetweenOperator = (
  oldFilter: RuleGroupType,
  newFilter: RuleGroupType
): RuleGroupType => {
  const updatedQuery = { ...newFilter };

  updatedQuery.rules = updatedQuery.rules.map(rule => {
    const ruleType = rule as RuleType;
    const oldRule = oldFilter.rules.find(
      x => (x as RuleType).id === ruleType.id
    ) as RuleType | undefined;

    if (!oldRule) {
      return handleNewRule(ruleType);
    }

    if (oldRule.operator === "between" && ruleType.operator !== "between") {
      return handleChangeFromBetween(ruleType, oldRule);
    }

    if (ruleType.operator === "between" && oldRule.operator !== "between") {
      return handleChangeToBetween(ruleType);
    }

    return ruleType;
  });

  return updatedQuery;
};

export default handleValueForBetweenOperator;
